import React, {useCallback, useState} from 'react';
import {Box} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import TranslationsInput from "../../translations/TranslationsInput";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {deepCopy} from "../../../helper/Helper";
import MaterialButton from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";
import IconButton from "@material-ui/core/IconButton";

const TableElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);

    const [content, setContent] = useState(props.content);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        const data = {
            content,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, content, translations]);

    const setCellContent = (row, col, value) => {
        const newContent = deepCopy(content);

        newContent[row][col] = value;

        setContent(newContent);
    };

    const setTranslationCell = (row, col, value) => {
        const newTranslations = deepCopy(translations);

        newTranslations[row][col] = value;

        setTranslations(newTranslations);
    };

    const addContentRow = () => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);
        const newTranslationsRowContent = [];
        const newRowContent = [];

        for (let i = 0; i < content[0].length; i++) {
            newTranslationsRowContent.push([]);
        }

        newTranslations.push(newTranslationsRowContent);

        for (let i = 0; i < content[0].length; i++) {
            newRowContent.push('');
        }

        newContent.push(newRowContent);

        setTranslations(newTranslations);
        setContent(newContent);
    };

    const addCol = () => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);

        newTranslations.forEach(row => {
            row.push([]);
        });

        newContent.forEach(row => {
            row.push('');
        });

        setTranslations(newTranslations);
        setContent(newContent);
    };

    const deleteCell = (row) => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);

        newTranslations.splice(row, 1);
        newContent.splice(row, 1);

        setTranslations(newTranslations);
        setContent(newContent);
    };

    const modalBody = (
        <Box mb={2}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                content[0].map((cell, cellIndex) => (
                                    <TableCell key={cellIndex}>
                                        <TranslationsInput
                                            field={'content'}
                                            defaultValue={cell}
                                            setDefaultValue={(value) => setCellContent(0, cellIndex, value)}
                                            translations={translations[0][cellIndex]}
                                            language={translationLanguage}
                                            label={GlobalTransIntl('text', intl)}
                                            setTranslations={(value) => setTranslationCell(0, cellIndex, value)}
                                            attributes={{
                                                required: true,
                                                autoComplete: 'off',
                                                multiline: true,
                                            }}
                                        />
                                    </TableCell>
                                ))
                            }
                            <TableCell>
                                <MaterialButton
                                    variant="contained"
                                    color="default"
                                    className={''}
                                    startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                                    onClick={addCol}
                                    aria-label={GlobalTransIntl('add', intl)}
                                    title={GlobalTransIntl('add', intl)}
                                >
                                    {GlobalTransIntl('add', intl)}
                                </MaterialButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            content.map((row, rowIndex) => (
                                (rowIndex > 0) &&
                                <TableRow key={rowIndex}>
                                    {
                                        row.map((cell, cellIndex) => (
                                            <TableCell scope="row" key={cellIndex}>
                                                <TranslationsInput
                                                    field={'content'}
                                                    defaultValue={cell}
                                                    setDefaultValue={(value) => setCellContent(rowIndex, cellIndex, value)}
                                                    translations={translations[rowIndex][cellIndex]}
                                                    language={translationLanguage}
                                                    label={GlobalTransIntl('text', intl)}
                                                    setTranslations={(value) => setTranslationCell(rowIndex, cellIndex, value)}
                                                    attributes={{
                                                        required: true,
                                                        autoComplete: 'off',
                                                        multiline: true,
                                                    }}
                                                />
                                            </TableCell>
                                        ))
                                    }
                                    {
                                        (content.length > 2) &&
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    deleteCell(rowIndex);
                                                }}
                                                aria-label={GlobalTransIntl('title_delete', intl)}
                                                title={GlobalTransIntl('title_delete', intl)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                                            </IconButton>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2}>
                <MaterialButton
                    variant="contained"
                    color="default"
                    className={''}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                    onClick={addContentRow}
                    aria-label={GlobalTransIntl('add', intl)}
                    title={GlobalTransIntl('add', intl)}
                >
                    {GlobalTransIntl('add', intl)}
                </MaterialButton>
            </Box>
        </Box>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
                modalSize={'xl'}
            >
                {modalBody}
            </ModuleElementActions>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                props.content[0].map((cell, cellIndex) => (
                                    <TableCell key={cellIndex}>{cell}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.content.map((row, rowIndex) => (
                            (rowIndex > 0) &&
                            <TableRow key={rowIndex}>
                                {
                                    row.map((cell, cellIndex) => (
                                        <TableCell scope="row" key={cellIndex}>
                                            {cell}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

TableElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const TableElementConfig = {
    title: 'table',
    content: [[''], ['']],
    translations: [[[]], [[]]],
}

export default TableElement;