import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs, faEnvelope, faPaperPlane, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import {format} from 'date-fns';
import {Box, Grid, Link, Modal, Snackbar} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import {FormattedMessage} from "react-intl.macro";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {Button} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useIntl} from "react-intl";
import Validation from "../../helper/Validation";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import Notifications from "../notifications/Notifications";
import {
    getPinUrl,
    helperCatchErrors,
    saveValueInClipboard,
    selectIconComponent
} from "../../helper/Helper";
import Spinner from "../spinner/Spinner";
import ButtonBootstrap from "react-bootstrap/cjs/Button";
import ReceiverForm from "../receiver/ReceiverForm";
import Api from "../../helper/Api";
import {Alert} from "react-bootstrap";
import SelectProjectWrapper from "../selectProject/selectProjectWrapper";
import Token from "../../helper/Token";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function PinsOpen() {
    const {state} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());

    const [cachedProject, setCachedProject] = useState(state.project ? state.project['@id'] : '');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [reload, setReload] = React.useState({0: false});

    // Modals
    const [modalPinId, setModalPinId] = useState('');
    const [modalSendPinOpen, setModalSendPinOpen] = React.useState(false);
    const [modalEditPinOpen, setModalEditPinOpen] = React.useState(false);

    // Edit pin modal
    const receiverRef = useRef();
    const [receiver, setReceiver] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loadingLanguages, setLoadingLanguages] = useState(true);
    const [normgroup, setNormgroup] = useState('');
    const [normgroups, setNormgroups] = useState(false);
    const [availableNormgroups, setAvailableNormgroups] = useState(false);
    const [loadingNormgroups, setLoadingNormgroups] = useState(true);
    const [loadingThreeSixtyRoles, setLoadingThreeSixtyRoles] = useState(true);
    const [comment, setComment] = useState('');
    const [referencePins, setReferencePins] = useState([]);
    const [referencePinSelectedRoles, setReferencePinSelectedRoles] = useState({});
    const [editReferencePins, setEditReferencePins] = useState(false);
    const [addReferencePinToken, setAddReferencePinToken] = useState('');
    const [updatedRoles, setUpdatedRoles] = useState(0);
    const [prepareSubmit, setPrepareSubmit] = useState(false);

    // Snackbar
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    // Notification
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationDeleteSuccess, setNotificationDeleteSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState(GlobalTransIntl('notification_send_success', intl));

    // Edit Modal Notifications
    const [notificationErrorEditModal, setNotificationErrorEditModal] = useState(false);
    const [errorMessageEditModal, setErrorMessageEditModal] = React.useState('');

    const assessmentUrl = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ],
        'procedures'
    );

    const [toolbarItems] = React.useState(Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN') ?
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTrans('pin')
            },
            {
                type: 'text',
                name: 'comment',
                label: GlobalTransIntl('comment', intl)
            }
        ] :
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTrans('pin')
            },
            {
                type: 'text',
                name: 'comment',
                label: GlobalTransIntl('comment', intl)
            },
            {
                type: 'select',
                getUrl: assessmentUrl,
                name: 'procedureObject',
                label: GlobalTrans('assessment'),
                filterOperator: 'equals'
            }
        ]
    );

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);

        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        setModalSendPinOpen(false);
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const onClickToken = (params) => {
        saveValueInClipboard(getPinUrl(params));
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const columns = [
        {
            field: 'token', headerName: GlobalTrans('pin'), width: 200,
            renderCell: (params) => {
                if (params.value) {
                    return <Link onClick={() => onClickToken(params.row)} color={"inherit"}
                                 underline={"none"}>{params.value}</Link>
                }
            }
        },
        {
            field: 'procedureObject', headerName: GlobalTrans('field_assessment'), width: 150,
            valueGetter: (params) => params.value.name,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'language', headerName: GlobalTrans('field_language'), width: 130,
            valueGetter: (params) => (params.value?.name) ? params.value.name : GlobalTransIntl('translation_default', intl),
        },
        {
            field: 'normgroup', headerName: GlobalTrans('field_normgroup'), width: 140,
            renderCell: (params) => {
                if (params.value) {
                    return <div title={params.value.name}>{params.value.name}</div>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'createdAt',
            headerName: GlobalTrans('field_created'),
            type: 'date',
            width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'changedAt',
            headerName: GlobalTrans('field_changed'),
            type: 'date',
            width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'comment', headerName: GlobalTrans('field_comment'), width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '';
                }
            }
        },
        {
            field: 'pinMails', headerName: GlobalTrans('field_receiver'), width: 160,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value.map((item) => item.email);
                } else {
                    return '-';
                }
            },
            renderCell: (params) => {
                if (params.value !== '-') {
                    return (
                        <div title={params.value}>
                            {params.value.map((item, key) => {
                                return ((key > 0) ? ',' : '') + item
                            })}
                        </div>
                    )
                }
            }
        },
        {
            field: 'threeSixtyRoleLabel', headerName: GlobalTrans('role'), width: 150,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value;
                } else {
                    return '-';
                }
            }
        }
    ];

    const getLanguages = useCallback(() => {
        Api.getLanguages(
            (res) => {
                setLanguages(res.data['hydra:member']);
                setLoadingLanguages(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [state.token, catchErrors, cancelToken.token]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                setNormgroups(res.data['hydra:member']);
                setLoadingNormgroups(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [catchErrors, state.token, cancelToken.token]);

    const getThreeSixtyRoles = useCallback(() => {
        Api.getApi(
            'three_sixty_roles',
            (res) => {
                setRoles(res.data['hydra:member'].filter(role => role.id !== 1));
                setLoadingThreeSixtyRoles(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [catchErrors, state.token, cancelToken.token]);

    useEffect(() => {
        if (Object.keys(state.project).length && (cachedProject && cachedProject !== state.project['@id'])) {
            setCachedProject(state.project['@id']);
            setReload({0: true});
        } else if (Object.keys(state.project).length && !cachedProject) {
            setCachedProject(state.project['@id']);
        }
    }, [cachedProject, state.project]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationDeleteSuccess(false);
        setNotificationError(false);
    };

    const openEditPinModal = (props) => {
        setModalEditPinOpen(true);
        setModalPinId(props.id);
        setNotificationErrorEditModal(false);
        setAddReferencePinToken('');
        setUpdatedRoles(0);
        setReferencePinSelectedRoles({});

        if (props.row?.threeSixtyRole === '/api/three_sixty_roles/1' && props.row.threeSixtyPins) {
            setReferencePins(props.row.threeSixtyPins);
            setEditReferencePins(true);

            if (!roles.length) {
                getThreeSixtyRoles();
            }
        } else {
            setReferencePins([]);
            setEditReferencePins(false);
            setLoadingThreeSixtyRoles(false);
        }

        setReceiver(props.row.pinMails);
        setComment(props.row.comment);
        setAvailableNormgroups(props.row.procedureObject.availableNormgroups)

        if (props.row.normgroup?.['@id']) {
            setNormgroup(props.row.normgroup['@id']);
        }

        if (loadingLanguages) {
            getLanguages();
        }

        if (loadingNormgroups) {
            getNormgroups();
        }
    };

    const openSendPinModal = (props) => {
        setModalSendPinOpen(true);
        setModalPinId(props.id);
    };

    const onSendTestLink = (props) => {
        setModalSendPinOpen(true);
        setModalPinId(props.id);
    };

    const columnActions = (props) => {
        // ToDo - check if assessment has browser test
        let browserTest = false;

        return (
            <React.Fragment>
                {
                    browserTest &&
                    <IconButton onClick={() => onSendTestLink(props)}
                                aria-label={GlobalTransIntl('title_send_browser_test_link', intl)}
                                title={GlobalTransIntl('title_send_browser_test_link', intl)}>
                        <FontAwesomeIcon icon={faEnvelope} size={"xs"}/>
                    </IconButton>
                }
                {
                    !browserTest &&
                    <IconButton onClick={() => openSendPinModal(props)}
                                aria-label={GlobalTransIntl('title_send_pin_link', intl)}
                                title={GlobalTransIntl('title_send_pin_link', intl)}>
                        <FontAwesomeIcon icon={faEnvelope} size={"xs"}/>
                    </IconButton>
                }
                <IconButton onClick={() => openEditPinModal(props)} aria-label={GlobalTransIntl('title_edit', intl)}
                            title={GlobalTransIntl('title_edit', intl)}>
                    <FontAwesomeIcon icon={faCogs} size={"xs"}/>
                </IconButton>
            </React.Fragment>
        )
    };

    const handleSendPinClose = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setValidEmail(true);

        setModalSendPinOpen(false);
    };

    const handleEditPinClose = () => {
        setModalEditPinOpen(false);
    };

    const validateEmail = () => {
        let validationEmail = Validation.isEmail(email);

        setValidEmail(validationEmail);

        return validationEmail;
    }

    const onSubmitSendLink = (e) => {
        e.preventDefault();
        resetNotifications();

        let validatedEmail = validateEmail(),
            data = {
                email,
                firstName,
                lastName,
                sendInvite: true,
            };

        if (validatedEmail) {
            axios.patch(config.apiUrl + `/pins/` + modalPinId, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'content-type': 'application/merge-patch+json'}
            }))
                .then(res => {
                    if (res.status === 200) {
                        setModalSendPinOpen(false);
                        setSuccessMessage(GlobalTransIntl('notification_send_success', intl));
                        setNotificationSuccess(true);
                        setReload({0: true});

                        setTimeoutWatcher(setTimeout(() => {
                            resetNotifications();
                        }, 3000));
                    } else {
                        setModalSendPinOpen(false);
                        setErrorMessage("Status " + res.status);
                        setNotificationError(true);
                    }
                })
                .catch(catchErrors);
        }
    }

    const onSubmitEditPin = (e) => {
        e.preventDefault();
        setNotificationErrorEditModal(false);

        receiverRef.current.submit();
    }

    const prepareSubmitPin = useCallback((newReceiver = undefined) => {
        setPrepareSubmit(true);

        if (newReceiver) {
            setReceiver(newReceiver);
        }

        if (Object.keys(referencePinSelectedRoles).length) {
            Object.keys(referencePinSelectedRoles).forEach(pinId => {
                const data = {
                    threeSixtyRole: referencePinSelectedRoles[pinId]
                };

                axios.patch(config.apiUrl + `/pins/` + pinId, data, config.axiosConfig(state.token, {
                    cancelToken: cancelToken.token,
                    headers: {'content-type': 'application/merge-patch+json'}
                }))
                    .then((res) => {
                        if (res.status === 200) {
                            setUpdatedRoles(prev => prev + 1);
                        } else {
                            setErrorMessage("Status " + res.status);
                            setNotificationError(true);
                            setPrepareSubmit(false);
                        }
                    })
                    .catch(catchErrors);
            });
        }
    },[referencePinSelectedRoles, cancelToken.token, catchErrors, state.token]);

    const submitPin = useCallback(() => {
        let data = {
            comment,
            normgroup,
            pinMails: receiver
        };

        if (editReferencePins) {
            data.threeSixtyPins = referencePins.map(pin => pin['@id']);
        }

        if (!receiverRef.current.validate()) {
            return;
        }

        axios.patch(config.apiUrl + `/pins/` + modalPinId, data, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'content-type': 'application/merge-patch+json'}
        }))
            .then((res) => {
                if (res.status === 200) {
                    handleEditPinClose();
                    setSuccessMessage(GlobalTransIntl('notification_success', intl));
                    setNotificationSuccess(true);
                    setReload({0: true});

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));
                } else {
                    setErrorMessage("Status " + res.status);
                    setNotificationError(true);
                }
            })
            .catch(catchErrors);
    }, [cancelToken.token, catchErrors, comment, intl, modalPinId, normgroup, receiver, state.token, editReferencePins, referencePins]);

    useEffect(() => {
        if (prepareSubmit && Object.keys(referencePinSelectedRoles).length === updatedRoles) {
            setPrepareSubmit(false);
            submitPin();
        }
    }, [prepareSubmit, referencePinSelectedRoles, updatedRoles, submitPin]);

    const removeReferencePin = useCallback((searchPinId) => {
        const pinIndex = referencePins.findIndex(pin => pin.id === searchPinId);

        setReferencePins(prev => {
            const newReferencePins = [...prev];

            newReferencePins.splice(pinIndex, 1);

            return newReferencePins;
        })
    }, [referencePins]);

    const getReferencePinName = (pin) => {
        if (pin?.firstName && pin?.lastName) {
            return pin.firstName + ' ' + pin.lastName;
        } else if (pin?.user) {
            return pin.user.firstName + ' ' + pin.user.lastName;
        }

        return null;
    }

    const getReferencePins = useCallback(() => {
        return referencePins.map(pin => (
            <Box key={pin.id}>
                <Grid container spacing={4}>
                    <Grid item sm={3} className={'align-self--center'}>
                        {pin.token}
                    </Grid>
                    <Grid item sm={4} className={'align-self--center'}>
                        {getReferencePinName(pin)}
                    </Grid>
                    <Grid item sm={3} className={'align-self--center'}>
                        <FormControl
                            variant={'outlined'}
                            className={'pin-edit--reference-pin-role'}
                            id={'pin-edit--reference-pin-role'}
                            required
                        >
                            <InputLabel>
                                {GlobalTransIntl('role', intl)}
                            </InputLabel>
                            <Select
                                id="pin-edit--reference-pin-role-select"
                                value={referencePinSelectedRoles?.[pin.id] || pin.threeSixtyRole}
                                onChange={(event) => {
                                    setReferencePinSelectedRoles(prev => ({
                                        ...prev,
                                        [pin.id]: event.target.value
                                    }));
                                }}
                                label={GlobalTransIntl('role', intl)}
                                IconComponent={selectIconComponent}
                            >
                                {
                                    roles.map((item) =>
                                        <MenuItem
                                            value={item['@id']}
                                            key={item['@id']}
                                            data-name={item.name}
                                            selected={item['@id'] === pin.threeSixtyRole}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={2} className={'align-self--center text-align-center'}>
                        <IconButton
                            onClick={() => removeReferencePin(pin.id)}
                            title={GlobalTransIntl('delete', intl)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        ))
    }, [referencePins, removeReferencePin, intl, roles, referencePinSelectedRoles]);

    const addReferencePin = (event) => {
        event.preventDefault();
        setNotificationErrorEditModal(false);
        const url = ApiFilterBuilder([
                {
                    name: 'token',
                    value: addReferencePinToken,
                }
            ],
            config.apiUrl + `/pins`
        );

        axios.get(url, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then((response) => {
                if (response?.data?.['hydra:member'].length) {
                    const foundPin = response.data['hydra:member'][0];

                    if (!referencePins.find(item => item['@id'] === foundPin['@id'])) {
                        setReferencePins(prev => {
                            const newReferencePins =  [...prev];

                            newReferencePins.push(foundPin);

                            return newReferencePins;
                        });

                        setAddReferencePinToken('');

                        return;
                    } else {
                        setErrorMessageEditModal(GlobalTransIntl('pin_already_used', intl));
                    }
                } else {
                    setErrorMessageEditModal(GlobalTransIntl('token_not_found', intl));
                }

                setNotificationErrorEditModal(true);
            })
            .catch(catchErrors);
    }

    const getAvailableNormgroups = () => {
        return normgroups.filter(normgroup => availableNormgroups.find(availableNormgroup => availableNormgroup === normgroup['@id']));
    }

    const getModals = () => {
        return (
            <Fragment>
                <Modal
                    open={modalSendPinOpen}
                    onClose={handleSendPinClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={'MuiModal-root'}
                >
                    <div className={'main-modal modal-size-md'}>
                        <form onSubmit={onSubmitSendLink}>
                            <Form.Group controlId="formLink">
                                <h2 className={'text-align-center'}>
                                    <FormattedMessage id='pin.open.modal.headline' defaultMessage='Send pin'/>
                                </h2>
                            </Form.Group>
                            <Row className={'justify-content-md-center'}>
                                <Col xs={12}>
                                    <Form.Group controlId="formEmail">
                                        <TextField error={!validEmail}
                                                   label={GlobalTransIntl('email', intl)}
                                                   id={'pin-open--email'}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => setEmail(e.target.value)}
                                                   helperText={GlobalTransIntl('error_email', intl)}
                                                   value={email}
                                                   required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group controlId="formFirstName">
                                        <TextField label={GlobalTransIntl('first_name', intl)}
                                                   id={'pin-open--firstname'}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => setFirstName(e.target.value)}
                                                   value={firstName}
                                                   required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group controlId="formLastName">
                                        <TextField label={GlobalTransIntl('last_name', intl)}
                                                   id={'pin-open--lastname'}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => setLastName(e.target.value)}
                                                   value={lastName}
                                                   required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={'auto'}>
                                    <Button variant="contained"
                                            color="primary"
                                            type="submit"
                                            className='pin-open--send'
                                            endIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                    >
                                        {GlobalTransIntl('button_send', intl)}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Modal>
                <Modal
                    open={modalEditPinOpen}
                    onClose={handleEditPinClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={'MuiModal-root'}
                >
                    <div className={'main-modal modal-size-lg'}>
                        <Spinner show={loadingLanguages} rowClass={'p-5'}/>
                        {
                            !loadingLanguages && !loadingNormgroups && !loadingThreeSixtyRoles &&
                            <form onSubmit={onSubmitEditPin}>
                                <Form.Group controlId="formLink">
                                    <h2 className={'text-align-center'}>
                                        <FormattedMessage id='pin.edit.modal.headline' defaultMessage='Edit pin'/>
                                    </h2>
                                </Form.Group>
                                <ReceiverForm
                                    ref={receiverRef}
                                    receiver={receiver}
                                    languages={languages}
                                    setErrorMessage={setErrorMessage}
                                    setNotificationError={setNotificationError}
                                    submitFunction={prepareSubmitPin}
                                />
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group controlId="formBasicNormgroup">
                                            <FormControl variant={'outlined'} className={'pin-create--form-control'}
                                                         id={'pin-create-normgroup'} required>
                                                <InputLabel>
                                                    {GlobalTransIntl('normgroup', intl)}
                                                </InputLabel>
                                                <Select
                                                    id="pin-create--normgroup-select"
                                                    value={normgroup}
                                                    onChange={(event) => {
                                                        setNormgroup(event.target.value)
                                                    }}
                                                    label={GlobalTransIntl('normgroup', intl)}
                                                    IconComponent={selectIconComponent}
                                                >
                                                    {
                                                        getAvailableNormgroups().map((item) =>
                                                            <MenuItem value={item['@id']} key={item.id}
                                                                      data-name={item.name}
                                                                      selected={item['@id'] === normgroup}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group controlId="formBasicComment">
                                            <TextField
                                                label={GlobalTransIntl('comment', intl)}
                                                id={'pin-create--comment'}
                                                value={comment}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setComment(e.target.value)
                                                }}
                                                multiline
                                                minRows={4}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    (editReferencePins) &&
                                    <>
                                        {
                                            (referencePins.length > 0) &&
                                            <Row>
                                                <Col xs={12}>
                                                    {getReferencePins()}
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col xs={12}>
                                                <Box mt={2}>
                                                    <Grid container spacing={2} className={'align-items-center'}>
                                                        <Grid item xs={10}>
                                                            <TextField error={!validEmail}
                                                                       label={GlobalTransIntl('token', intl)}
                                                                       id={'pin-open--reference-pins-add'}
                                                                       autoComplete={'off'}
                                                                       variant="outlined"
                                                                       onChange={(e) => setAddReferencePinToken(e.target.value)}
                                                                       value={addReferencePinToken}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Button
                                                                className={'pin-open--reference-pins-add-button'}
                                                                variant={"contained"}
                                                                onClick={(event) => addReferencePin(event)}
                                                                fullWidth
                                                            >
                                                                {GlobalTransIntl('add', intl)}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Notifications
                                                                error={notificationErrorEditModal}
                                                                errorMessage={errorMessageEditModal}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                <Row className={'justify-content-md-center'}>
                                    <Col xs={'auto'}>
                                        <ButtonBootstrap variant="primary" type="submit" className='pin-edit--submit'>
                                            {GlobalTransIntl('button_save', intl)}
                                        </ButtonBootstrap>
                                    </Col>
                                </Row>
                            </form>
                        }
                    </div>
                </Modal>
            </Fragment>
        )
    }

    const content = (
        <React.Fragment>
            <LoadingDataGrid
                apiUrl={'pins'}
                columns={columns}
                toolbarItems={toolbarItems}
                columnActions={columnActions}
                dataGridFilter={
                    [
                        {name: 'status', value: '/api/pin_statuses/1'},
                        {name: 'project', value: state.project['@id']}
                    ]
                }
                reload={reload}
                deleteAction={!Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')}
            />
            <Notifications
                cols={{
                    xs: 12,
                    lg: {
                        span: 6,
                        offset: 3
                    }
                }}
                success={notificationSuccess}
                delete={notificationDeleteSuccess}
                error={notificationError}
                errorMessage={errorMessage}
                successMessage={successMessage}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} variant="success" sx={{width: '100%'}}>
                    {GlobalTransIntl('clipboard_saved', intl)}
                </Alert>
            </Snackbar>
            {
                getModals()
            }
        </React.Fragment>
    );

    return (
        <Paper>
            {
                (
                    (!Object.keys(state.project).length) &&
                    <SelectProjectWrapper id='pins-create' rowClass={'p-5'}/>
                )
                ||
                content
            }
        </Paper>
    );
}

export default PinsOpen;